import styled, { css } from 'styled-components';
import { ACCENT_TERTIARY, FONT_FAMILY_BODY, FONT_FAMILY_TITLE, HIGHLIGHT_PRIMARY, SURFACE_4, SURFACE_6, TEXT_100, TEXT_200 } from 'style/constants';
import { TEXT_BODY_XS_REGULAR, TEXT_LABEL_M_BOLD, TEXT_TITLE_M } from 'style/design-system/textStyles';
import { MULTI_LINE_ELLIPSIS, desktopOnly, mobileOnly } from 'style/mixins';
import InlineTextEditor from 'components/admin2/ui/InlineTextEditor';
import EventDate from './DateTime/Date';
import Icon from 'components/ui/Icon';
import QuickActionMenu from 'components/admin2/ui/QuickActionMenu';
import { FULL_EVENT_CARD_MAX_HEIGHT, FULL_EVENT_CARD_MAX_WIDTH, FULL_EVENT_CARD_MIN_HEIGHT, FULL_EVENT_CARD_MIN_WIDTH } from '../../utils';

export const PrimaryInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const SecondaryInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${mobileOnly`gap: 8px;`}
`;

export const Description = styled(InlineTextEditor)`
  width: 100%;
  ${mobileOnly`display: none;`};

  & .mce-content-body {
    & * {
      ${props => props.readOnly && MULTI_LINE_ELLIPSIS}
      font-family: ${FONT_FAMILY_BODY};
    }
    color: ${TEXT_200};
  }
`;

export const Title = styled(InlineTextEditor)`
  width: 100%;

  & .mce-content-body {
    & * {
      ${props => props.readOnly && MULTI_LINE_ELLIPSIS}
      font-family: ${FONT_FAMILY_TITLE};
      ${mobileOnly`
        ${TEXT_LABEL_M_BOLD}
        font-size: 0.875rem !important;
      `};
    }
    color: ${TEXT_100};
  }
`;

export const TimeContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;


export const StyledEventDate = styled(EventDate)`
  ${mobileOnly`display: none;`}
`;

export const Wrapper = styled.section<{ firstLabelColor?: string; }>`
  display: flex;
  justify-content: flex-start;
  border-radius: 10px;
  background-color: ${SURFACE_4};
  position: relative;
  min-width: ${FULL_EVENT_CARD_MIN_WIDTH}px;
  max-width: ${FULL_EVENT_CARD_MAX_WIDTH}px;
  min-height: ${FULL_EVENT_CARD_MIN_HEIGHT}px;
  max-height: ${FULL_EVENT_CARD_MAX_HEIGHT}px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: ${props => props.firstLabelColor || SURFACE_6};
    border-radius: 5px 0 0 5px;
  }
`;

export const ContentContainer = styled.div<{ showImage: boolean; }>`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${({ showImage }) => showImage ? '70%' : '100%'};
  ${({ showImage }) => !showImage && css`margin-left: 4px;`};
`;

export const FullEventActionsMenu = styled(QuickActionMenu)`
  height: fit-content;
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 2;

  & div button {
    justify-content: flex-start;
  }
`;

export const EditIcon = styled(Icon).attrs({
  name: 'adminbarEditor',
})`
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 9px;

  & svg {
    width: 14px;
    height: 14px;

    & path {
      fill: ${HIGHLIGHT_PRIMARY} !important;
    }
  }
`;

export const TrashIcon = styled(Icon).attrs({
  name: 'trash',
})`
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 9px;

  &  svg {
    width: 14px;
    height: 14px;
    & path {
      fill: ${HIGHLIGHT_PRIMARY} !important;
    }
  }
`;
