import React from 'react';
import { FieldSet, Filter, FiltersWrapper, OptionToggle, OptionsToggle, FiltersButton, StyledExpandable, FilterIcon, ArrowIcon, FiltersVisualization, GameTypeSpan, RemainingFilters, StyledMobileModalBottomSheet, BottomSheetSaveButton } from './styles';
import FormInput from 'components/ui/v2/Inputs';
import { StyledDropdown } from '../styles';
import { useAdminTranslation } from 'hooks/use-translation';
import useToggle from 'hooks/use-toggle';
import { useFortniteLeaderboardContext } from './Context/Context';
import { GameType } from '../utils';
import { isMobileLayout } from 'services/device';
import { useSelector } from 'react-redux';
import { Portal } from 'react-portal';

const CommonFilters: React.FC = () => {
  const { isRanked, isBattleRoyale, onChangeGameType, isPanel, seasons, tournaments, loadingFilters, sessions } = useFortniteLeaderboardContext();
  const { t } = useAdminTranslation();
  const isMobile = useSelector(isMobileLayout);

  const short = isPanel || isMobile;

  const handleGameTypeChange = (value: GameType) => () => {
    onChangeGameType(value);
  };

  const onChangeSeason = (value: Record<string, any>) => {
    seasons.onChange(value.value);
  };

  const onChangeTournament = (value: Record<string, any>) => {
    tournaments.onChange(value.value);
  };

  const onChangeSession = (value: Record<string, any>) => {
    sessions.onChange(value.value);
  };

  return (
    <>
      <Filter flex={1} short={short} disabled={loadingFilters}>
        <FormInput.Label>{t('GAME_TYPE')}</FormInput.Label>
        <OptionsToggle>
          <OptionToggle onClick={handleGameTypeChange('battle-royale')} active={isBattleRoyale}>{t('BATTLE_ROYALE')}</OptionToggle>
          <OptionToggle onClick={handleGameTypeChange('zero-build')} active={!isBattleRoyale}>{t('ZERO_BUILD')}</OptionToggle>
        </OptionsToggle>
      </Filter>

      <Filter short={short} disabled={loadingFilters}>
        <FormInput.Label>{t('SEASON')}</FormInput.Label>
        <StyledDropdown
          isPanel={isPanel}
          placeholder={t('SELECT_SEASON')}
          isAdmin={false}
          padding="0"
          onChange={onChangeSeason}
          options={seasons.options}
          value={seasons.selected}
        />
      </Filter>

      {
        !isRanked && (
          <>
            <Filter short={short} disabled={loadingFilters}>
              <FormInput.Label>{t('TOURNAMENT')}</FormInput.Label>
              <StyledDropdown
                isPanel={isPanel}
                placeholder={t('SELECT_TOURNAMENT')}
                isAdmin={false}
                padding="0"
                onChange={onChangeTournament}
                options={tournaments.options}
                value={tournaments.selected}
                isDisabled={!seasons.selected}
              />
            </Filter>

            {
              tournaments.selected?.value && sessions.options.length === 1 ? null : (
                <Filter short={short} disabled={loadingFilters}>
                  <FormInput.Label>{t('SESSION')}</FormInput.Label>
                  <StyledDropdown
                    isPanel={isPanel}
                    placeholder={t('SELECT_SESSION')}
                    isAdmin={false}
                    padding="0"
                    onChange={onChangeSession}
                    options={sessions.options}
                    value={sessions.selected}
                    isDisabled={!tournaments.selected}
                  />
                </Filter>
              )
            }
          </>
        )
      }
    </>
  );
};

const Filters: React.FC = () => {
  const isMobile = useSelector(isMobileLayout);
  const { isPanel, isBattleRoyale, seasons, tournaments, sessions, query, setQuery } = useFortniteLeaderboardContext();
  const { t } = useAdminTranslation();
  const [isExpanded, toggle] = useToggle(false);

  const short = isPanel || isMobile;

  return (
    <>
      <FiltersWrapper columnGap={10}>
        {!short && <CommonFilters />}

        <FiltersWrapper columnGap={8} style={{ flex: 1 }}>
          {
            short && (
              <FiltersButton onClick={toggle}>
                <FilterIcon />
                {t('FILTERS')}
                <ArrowIcon name={isExpanded ? 'arrowDropDownUp' : 'arrowDropDownDown'} />
              </FiltersButton>
            )
          }

          <Filter flex={1} short={short}>
            {!short && (<FormInput.Label>{t('ICON_NAME_SEARCH')}</FormInput.Label>)}
            <FieldSet>
              <FormInput.InputIcon name="search" />
              <FormInput.TextInput
                placeholder={t('SEARCH_BY_NAME')}
                value={query}
                onChange={setQuery}
              />
            </FieldSet>
          </Filter>
        </FiltersWrapper>

        <StyledExpandable isExpanded={isExpanded && !isMobile}>
          <CommonFilters />
        </StyledExpandable>
      </FiltersWrapper>
      {short && (
        <FiltersVisualization>
          <GameTypeSpan>{isBattleRoyale ? t('BATTLE_ROYALE') : t('ZERO_BUILD')}</GameTypeSpan>
          <RemainingFilters>{[seasons.selected?.label, tournaments.selected?.label, sessions.selected?.label].filter(value => Boolean(value)).join(' -> ')}</RemainingFilters>
        </FiltersVisualization>
      )}

      <Portal>
        <StyledMobileModalBottomSheet
          isOpen={isExpanded && isMobile}
          onClose={toggle}
          titleKey="DEFAULT_DATA"
        >
          <CommonFilters />
          <BottomSheetSaveButton onClick={toggle}>{t('ADMIN_LABEL_DONE')}</BottomSheetSaveButton>
        </StyledMobileModalBottomSheet>
      </Portal>
    </>
  );
};

export default Filters;
