import { getDefaultRichTextEditorData } from 'utils';
import { GridTextAlignmentOptions } from '../../admin-bridge/EditorModal/ModalComponents/GridTextAlignment/types';
import { ImageCardProps } from './ImageCard';
import { MAESTRO_BLACK } from 'style/constants';
import { ImageLayout, OverflowBehavior } from '../../admin-bridge/EditorModal/ModalComponents/OptionRow';
import shortid from 'shortid';
import { injectT, TTranslator } from 'hooks/use-translation';
import { lazyFn } from 'utils/lazy';
import { DEFAULT_CTA_BEHAVIOR, DEFAULT_CTA_HEIGHT_SIZE } from 'components/admin-bridge/CTAEditorModal/utils';
import { getDefaultEditorState, getDefaultHeadingNode, getDefaultTextNode } from 'components/ui/RichTextEditorV3/getDefaultEditorState';
import { IPageBlockProps } from 'services/app/models/ILandingPageContent';

export type IImageGalleryProps = IPageBlockProps<IImageGalleryItem>;

export interface IImageGalleryItem {
  arrayId: string;
  data: IImageGallery;
  kind: string;
}

export type AspectRatio = `${number} / ${number}`;

export const ASPECT_RATIO_LABEL_MAP: Record<AspectRatio, string> = {
  '1 / 1': '1:1',
  '4 / 3': '4:3',
  '3 / 2': '3:2',
  '16 / 9': '16:9',
  '21 / 9': '21:9',
  '3 / 4': '3:4',
  '2 / 3': '2:3',
  '9 / 16': '9:16',
};

export interface IImageGallery {
  aspectRatio: {
    desktop: AspectRatio;
    mobile: AspectRatio;
    mobileEqualDesktop: boolean;
  }
  background: {
    color: string;
    customColor: string;
    image: {
      desktop: string;
      mobile: string;
      show: boolean;
    }
  }
  gridTextAlignment: GridTextAlignmentOptions;
  imageCards: ImageCardProps[];
  imageLayout: ImageLayout;
  imagesPerRow: number;
  overflowBehavior: {
    desktop: OverflowBehavior;
    mobile: OverflowBehavior;
  }
  showCta: boolean;
  showDescription: boolean;
  showLabel: boolean;
  showTitle: boolean;
  title: string;
  titleV2?: string;
}

export const defaultImageCard = injectT((t) => (homeId: string): ImageCardProps => ({
  cta: {
    height: {
      desktop: DEFAULT_CTA_HEIGHT_SIZE,
      mobile: DEFAULT_CTA_HEIGHT_SIZE,
    },
    variant: 'solid',
    text: t('ADMIN_IMAGE_BLOCK_DEFAULT_CTA_TEXT'),
    behavior: DEFAULT_CTA_BEHAVIOR(homeId),
  },
  description: getDefaultRichTextEditorData({
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempor id consectetur viverra et. Cras semper quam venenatis morbi.',
  }),
  descriptionV2: getDefaultEditorState([
    getDefaultTextNode({
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempor id consectetur viverra et. Cras semper quam venenatis morbi.',
    }),
  ]),
  label: getDefaultRichTextEditorData({
    text: 'Label',
  }),
  labelV2: getDefaultEditorState([
    getDefaultTextNode({
      text: 'Label',
    }),
  ]),
  id: shortid.generate(),
  image: {
    desktop: '',
    mobile: '',
  },
  link: '',
  title: getDefaultRichTextEditorData({
    text: t('ADMIN_IMAGE_BLOCK_DEFAULT_TITLE'),
    typographicalEmphasis: 'BOLD',
  }),
  titleV2: getDefaultEditorState([
    getDefaultHeadingNode({
      tag: 'h3',
      children: [
        getDefaultTextNode({
          text: t('ADMIN_IMAGE_BLOCK_DEFAULT_TITLE'),
          textFormatType: 'bold',
        }),
      ],
    }),
  ]),
}));

const createDefaultImageGalleryData = (homeId: string, t: TTranslator): IImageGallery=> ({
  background: {
    color: 'surface1',
    customColor: MAESTRO_BLACK,
    image: {
      show: false,
      mobile: '',
      desktop: '',
    },
  },
  imageCards: [defaultImageCard(t, homeId)],
  imageLayout: 'contentBelow',
  overflowBehavior: {
    desktop: 'slider',
    mobile: 'slider',
  },
  imagesPerRow: 4,
  aspectRatio: {
    desktop: '1 / 1',
    mobile: '1 / 1',
    mobileEqualDesktop: true,
  },
  gridTextAlignment: 'bottomLeft',
  showCta: true,
  showDescription: true,
  showTitle: true,
  showLabel: true,
  title: getDefaultRichTextEditorData({
    text: t('ADMIN_LABEL_IMAGE_GALLERY'),
    typographicalEmphasis: 'BOLD',
    fontSize: 20,
  }),
  titleV2: getDefaultEditorState([
    getDefaultHeadingNode({
      tag: 'h2',
      children: [
        getDefaultTextNode({
          text: t('ADMIN_LABEL_IMAGE_GALLERY'),
          textFormatType: 'bold',
          fontSize: 20,
        }),
      ],
    }),
  ]),
});

export const DEFAULT_IMAGE_GALLERY = injectT(
  (t) => lazyFn(
    () => (homeId: string): IImageGallery => (createDefaultImageGalleryData(homeId, t)),
  ),
);
